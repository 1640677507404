.userList__btn__wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.tab-item-wrapper p {
  color: #000;
  font-size: 0.875rem !important;;
  text-transform: capitalize;
}

.down {
  background-color: #2e78ca !important;
  box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 25%;
}

.down:hover {
  transform: translateY(5px);
  box-shadow: inset 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.export-wrapper {
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 4px #808080ba;
  box-shadow: 0px 2px 4px #808080ba;
}

.export {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newexport-wrapper {
  display: flex;
  align-items: center;
}

.nav-tabs .nav-link {
  color: #15136d;
  font-weight: 600;
  text-transform: uppercase;
}

.newexport {
  width: 40%;
}

p.newexport-head {
  color: #525f7f !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}

p.newexport-text {
  display: inline-block;
}

@media screen and (max-width: 520px) {
  .app-header {
    height: 100px;
    display: -webkit-inline-box;
    padding: 5px 0;
  }

  .app-header h3 {
    font-size: 1.3rem;
  }

  .app-body {
    margin-top: 100px;
  }

  .fa {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
  }

  .main .container-fluid {
    padding: 0 10px;
  }

  .nav-tabs .nav-link {
    width: 30%;
    font-size: 12px;
    padding: 5px;
  }

  .nav-tabs .nav-link {
    font-weight: 700;
  }
}

.card-body h4 {
  color: #122a6c;
  text-transform: uppercase;
}