.white-link {
    color: rgb(227, 221, 221);
    text-decoration: none;
  }
.white-link1 {
    color:white;
    
  }
  .font-weight-bold {
    font-weight: bolder;
  }
  .enquiryfrom{
    background: radial-gradient(circle at 55% 92%,#426691 0 12%,transparent 12.2%),radial-gradient(circle at 94% 72%,#426691 0 10%,transparent 10.2%),radial-gradient(circle at 20% max(78%,350px),#263a53 0 7%,transparent 7.2%),radial-gradient(circle at 0 0,#263a53 0 40%,transparent 40.2%),#1f2e43;
}
.wrap-contact100{
  background: #fff;
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.contact100-form-title{
  align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 64px 15px;
    position: relative;
    width: 100%;
    z-index: 1;
}
.contact100-form-title:before {
  background-color: rgba(9,45,95,.9);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.form-group label {
  flex-basis: 20%;
  margin-right: 0px;
}

.form-group input {
  flex-basis: 90%; 
}
.col-form-label{
  font-size: inherit;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: calc(0.375rem + 1px);
    padding-top: calc(0.375rem + 1px);
    color:#072c5e;
}
.error-message{
  color:rgba(255, 0, 0, 0.918);
}
h2{
  margin-top: 5px;
}

.enquiry-fromm-11, .enquiry-fromm-12 {
  box-shadow: 0 6px 24px 0 rgba(0,0,0,.05), 0 0 0 1px rgba(0,0,0,.08);
}
.enquiry-fromm-11 {
  background-color: #ffffff8f;
  background-image: url(https://media.istockphoto.com/vectors/contact-us-concept-design-flat-smartphone-background-vector-internet-vector-id1271542528?b=1&k=20&m=1271542528&s=612x612&w=0&h=1xJqGOrsfXo1zzNrGmh5JoEJ4pQFOGyaxwj5pBzShvk=);
  background-position: 50%;
}
.enquiry-fromm-12 {
 /* background: radial-gradient(circle at 55% 92%,#426691 0 12%,transparent 12.2%),radial-gradient(circle at 94% 72%,#426691 0 10%,transparent 10.2%),radial-gradient(circle at 20% max(78%,350px),#263a53 0 7%,transparent 7.2%),radial-gradient(circle at 0 0,#263a53 0 40%,transparent 40.2%),#1f2e43;*/
 background-color: #fff;
}
button.btnenquiry.mb-3, button.btnenquirycontactus {
  background-color: #072b5b;
  color: #fff;
  font-weight: 500;
  padding: 6px 30px;
}
button.btnenquirycontactus {
  align-items: center;
  background-color: #092d5f;
  border-radius: 25px;
  color: #fff;
  display: flex;
  font-family: Poppins-Regular;
  font-size: 16px;
  height: 50px;
  justify-content: center;
  line-height: 1.2;
  min-width: 160px;
  padding: 0 20px;
  transition: all .4s;

}
.button.btnenquirycontactus:hover{
  background-color: rgb(121, 94, 58);
}

.link-set{
  text-decoration: none;
  color: black;
}
.link-set:hover {
  text-decoration: none;
  color: black;
}