@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,300&display=swap');

.hbc-card-title{
    font-family: 'Poppins';
    font-size: 21px;
    font-weight: 600;
}
.card-p{
    color: #484141;
    font-weight: 400 !important;
}