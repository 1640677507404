.footer-p-50{
    padding: 50px;
}

.fa-linkedin:before {
    color: #ffffffbd;
    content: "\f0e1";
    background: #2955bb;
    padding: 2px;
}

.fa-x-twitter:before {
    color: #000;
    content: "\e61b";
    background: #fff;
    padding: 1px;
}
.fa-instagram:before {
    color: #fff;
    content: "\f16d";
    background: linear-gradient(29.61deg, #f38334 0%, #da2e7d 	50.39%, #6b54c6 100%);
    padding: 2px;
}
.fa-facebook:before {
    color: #fff;
    content: "\f09a";
    background: #0d6fed;
    padding: 2px;
}

@media (max-width: 600px){

    .footer-p-50{
        padding: 25px 0px;
    }
}