.withLogo {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
}

.withLogo__left {
  flex: 4;
  padding-top: 70px;
  background-color: #092d5f;
  @media screen and (max-width: 991px) {
    padding: 40px;
    flex: 2;
  }
}

.withLogo__right {
  flex: 6;
  min-width: 65%;
  @media screen and (max-width: 991px) {
    flex: 8;
  }
}

.withLogo__logo {
  height: 5rem;
  @media screen and (max-width: 991px) {
    height: 3rem;
  }
}

.withLogo_lef_image {
  display: none;
  //Temporary
  @media screen and (min-width: 791px) {
    display: block;
    width: 100%;
    height: 270px;
    margin-top: 130px;
  }
}

.authentication__login-wrapper {
  padding-top: 70px;
}

.authentication__login-container {
  width: 70%;
  margin: 0 auto;
}

.authentication__login-heading {
  color: #2e78ca;
  font-weight: bold;
}

.authentication__login-form {
  margin-top: 60px;
}

@media only screen and (max-width: 768px) {
  .authentication__login__form-group {
    margin-top: 20px;
  }
}

.authentication__login__form__row-2 {
  margin-top: 30px;
}

.authentication__login__form__submit-button {
  margin-top: 20px;
  padding: 15px 50px !important;
}

.authentication__login__to-register {
  margin: 20px 0 50px 0;
}

.btn-primary {
  color: #fff;
  background-color: #2e78ca;
  border-color: #2e78ca;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2765ab;
  border-color: #255fa0;
}

a {
  color: #2e78ca;
  text-decoration: none;
  background-color: transparent;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.95rem + 2px);
  padding: 0.475rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: none!important;
}