.swal-modal{
    transform: scale(0.7);
    transform-origin: center;
    transition: transform 0.8s ease-in-out;
    }
  
    .swal-overlay--show-modal .swal-modal{
  
      animation: showSweetAlert;
    }
    @keyframes showSweetAlert{
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    1% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
    }
    45% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
    80% {
        -webkit-transform: scale(.95);
        transform: scale(.95);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
  
    }
  