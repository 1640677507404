.text-animation {
    position: relative;
    height: 30px;
    overflow: hidden;
  }
  
  .animated-word {
    position: absolute;
    opacity: 0;
    color: aqua;
    transform: translateY(-100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .animated-word.active {
    opacity: 1;
    transform: translateY(0);
  }
  