.userList__btn__wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  .userList__btn__wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .main .container-fluid {
    padding: 0 15px;
  }
}

@media screen and (max-width: 520px) {
  .app-header {
    height: 100px;
    display: -webkit-inline-box;
    padding: 5px 0;
  }

  .app-header h3 {
    font-size: 1.3rem;
  }

  .app-body {
    margin-top: 100px;
  }

  .fa {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
  }

  .user__form__wrapper {
    grid-template-columns: 1fr;
  }
}

.card-header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

.border-0 {
  border: 0 !important;
}

.card-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.table-responsive {
  overflow-y: auto;
  height: 55vh;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
}

.table {
  border-collapse: collapse;
  width: 100%;
  background: #fff !important;
}

table.align-items-center.table-flush.table {
  height: 1% !important;
}

.table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.card .table th {
  padding: 5px 10px;
  font-size: 15px;
  text-align: center;
}

.card .table td {
  padding: 5px 10px;
  font-size: 14px;
  text-align: center;
}

.table .thead-light th {
  background-color: #f6f9fc;
  color: #122a6c;
}

.table .thead-light th {
  border-color: #e9ecef;
}

.table thead th {
  vertical-align: top;
  border-bottom: 2px solid #c8ced3;
}

.table thead th {
  font-size: 0.75rem;
  text-transform: uppercase;
  border-bottom: 1px solid #e9ecef;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.card-footer {
  padding: 1.25rem 1.5rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.card-footer .fa {
  background: #ffffff;
  border-radius: 100%;
  color: #8898aa;
  width: 36px;
  height: 36px;
  line-height: 35px;
  text-align: center;
  display: block;
  margin-right: 0px;
  font-weight: 900;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem;
}

.pagination > .active > a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  margin-left: 3px;
  line-height: 1.25;
  color: #8898aa;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.pagination > li > a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 3px;
  border-radius: 50% !important;
  width: 36px;
  height: 36px;
  font-size: 0.875rem;
  text-decoration: none;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
  z-index: 0;
  color: #fff;
  background-color: #20a8d8;
  border-color: #20a8d8;
}

.pagination > li > a,
.pagination > li > span {
  background-color: #fff;
  cursor: pointer;
  position: relative;
  padding: 0.5rem 0.5rem;
  margin-left: 3px;
  line-height: 1.25;
  color: #8898aa;
  border: 1px solid #dee2e6;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset !important;
  border: none !important;
  width: 55px;
  height: 36px;
}
