.withLogo {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
}

.withLogo__left {
  flex: 4;
  padding-top: 70px;
  background-color: #092d5f;
  @media screen and (max-width: 991px) {
    padding: 40px;
    flex: 2;
  }
}

.withLogo__right {
  flex: 6;
  min-width: 65%;
  @media screen and (max-width: 991px) {
    flex: 8;
  }
}

.withLogo__logo {
  height: 5rem;
  @media screen and (max-width: 991px) {
    height: 3rem;
  }
}

.withLogo_lef_image {
  display: none;
  //Temporary
  @media screen and (min-width: 791px) {
    display: block;
    width: 100%;
    height: 270px;
    margin-top: 130px;
  }
}

.form-control:focus {
  border: 1.5px solid #2e78ca !important;
  box-shadow: none !important;
}

.btn-primary {
  color: #fff;
  background-color: #2e78ca;
  border-color: #2e78ca;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2765ab;
  border-color: #255fa0;
}

a {
  color: #2e78ca;
  text-decoration: none;
  background-color: transparent;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.95rem + 2px);
  padding: 0.475rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: none!important;
}

.authentication__register-wrapper {
  margin: 5rem 2rem;
}

.authentication__register-container {
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
}

.authentication__register__heading {
  text-align: center;
  color: #2e78ca;
  font-weight: bold;
}

.authentication__register__form {
  margin: 2rem 0;
}

.authentication__register__form__country-code {
  padding: 1px;
  min-width: 30px;
  font-weight: bold !important;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .authentication__register__form-group {
    margin-top: 20px;
  }
}

.authentication__register__form__row-2 {
  margin-top: 30px;
  @media only screen and (max-width: 768px) {
    margin-top: -20px;
  }
}

.authentication__register__form__checkbox {
  margin-right: 7px;
}

.authentication__register__terms-container {
  margin: 2rem 0 !important;
  display: flex;
  align-items: flex-start;
  input {
    margin-top: 5px;
  }
}

.authentication__register__form__btn-container {
  text-align: center;
}

.authentication__register__form__submit-button {
  margin-top: 20px;
  padding: 15px 50px !important;
}

.authentication__register__to-login {
  text-align: center;
  margin: 1rem 0;
}

.authentication__info_img {
  height: 25px;
  position: relative;
  top: -2px;
  padding: 5px;
}

.authentication__info {
  user-select: none;
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 185px;
  width: 300px;
  background: rgba(92, 92, 92, 1);
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  @media screen and (max-width: 425px) {
    width: 50vw;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 10vw;
    left: 180px;
  }
  @media screen and (max-width: 768px) {
    width: 20vw;
    left: 180px;
  }
}

.authentication__info_img:hover ~ .authentication__info {
  display: block;
  opacity: 1;
}
