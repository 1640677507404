/* Home Loan Calculator */
.input-group {
  color: #dad0d0;
}
.underLine{
  text-decoration: none;
  border-bottom: 3px solid #e45656;
}

.input {
  width: 70%;
  border-top-right-radius: 5px;
}

.border-right {
  border-right-width: 3px;
  border-right-style: solid;
  border-image: linear-gradient(#fff,rgba(84,84,84,.4),rgba(0,0,0,0)) 1 100%;

}

.right-side-cal {
  font-size: 36px;
  color: rgb(254 82 51);
  font-weight: 500;
}

.home-calculator {
  font-size: 45px;
  color: #072c5e;
  font-weight: 500;
}

.hr-loan {
  color: #ba1f00;
  border: 1px solid #ba1f00 !important;
  opacity: 1;
  margin-right: 100px;
}

.tab-group {
  background: #E5E5E5;

  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  max-width: 500px;
  border-radius: 5px;


}

.custom-tab-content {
  border: 2px solid white;
}

.disclaimer {
  font-size: 12px !important;
  color: #0000004d !important;
  font-family: Sarabun, sans-serif;
}

.hr-Home {
  color: #00C4B2;
  border: 2px solid #ba1f00;
  opacity: 1;
  margin-right: 590px;
}
.rightSec-leble{
  color:#fff;
}
.cal-pl-50{
  padding-left: 50px !important; 
}

/*  screens smaller than or equal to 768px */
@media screen and (max-width: 768px) {
  .hr-Home {
    margin-right: 0px;
  }
  .cal-pl-50{
    padding-left: 0px !important;
  }
  .home-calculator{
    font-size: 28px;
    text-align: center;
  }
}

/*  screens between 769px and 1024px */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .hr-Home {
    margin-right: 700px;
  }
}

/*  screens larger than 1024px */
@media screen and (min-width: 1025px) {
  .hr-Home {
    margin-right: 850px;
  }
}

.p-50 {
  padding-left: 50px;
}

.custom-calc-btn.active {
  background-color: #979898 !important;
  color: white !important;
}

.custom-calc-btn:not(.active) {
  color: black !important;
}

.font-weight-500 {
  font-weight: 500;
}