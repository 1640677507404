/* Driven by inovation */
.heading-txt{
    font-size: 48px;
      font-weight: 700;
      line-height: 1.2em;
      color: #fff;
      font-family:Poppins,sans-serif!important;
  }

  .mb-4 {
    margin-bottom: 1.5rem!important;
}
  .custom-before::before {
    content: "";
     
      display: inline-block;
      top: 10px;
      left: 10px;
      width: 4px;
      margin-right: 8px;
      height: 35px;
      background: #00C4B2;
  }
  
  p {
   
    margin-top: 0;
    margin-bottom: 1rem;
}

.plus{
    font-size: 55px;
    font-weight: bolder;
}
 .text{
    padding-left: 12px;
    font-family:Poppins,sans-serif!important;
 }
 body{
    font-family: Poppins,sans-serif;
 }
 .driv-heading{
  font-size: 45px;
 }
 .underLine{
  text-decoration: none;
  border-bottom: 3px solid #e45656;
}
.myCard{
  width: 23% !important;
}
.myCard:hover{
  background-color: #e45656;
  color: #fff;
  cursor: pointer;
  transition: 1s;
}

 /* Styles for screens smaller than or equal to 768px */
@media screen and (max-width: 768px) {
  .driv-heading{
    font-size: 28px;
  }
  .myCard{
    width: 100% !important;
  }
  }
  
  /* Styles for screens between 769px and 1024px */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .hr-Driven {
      margin-right: 400px;
    }
  }
  
  /* Styles for screens larger than 1024px */
  @media screen and (min-width: 1025px) {
    .hr-Driven {
      margin-right: 495px;
    }
  }