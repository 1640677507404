.userList__btn__wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

span.input-group-text:hover {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  color: #ffffff;
  background-color: #2e78ca !important;
  cursor: pointer;
}

span.input-group-text:focus {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  color: #ffffff;
  background-color: #2e78ca !important;
  cursor: pointer;
}

.form-control-label {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.form-control-alternative {
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;
}

.form-control {
  font-size: 0.875rem;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.375rem;
  border: 1px solid #cfcccc;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.selectStyle {
  z-index: 99 !important;
}

i.fa.fa-download.fa-lg {
  background: #2e78ca !important;
  border-radius: 3px !important;
  color: #ffffff;
  width: 45px;
  height: 37px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  padding: 5px;
}

.fa.fa-download.fa-lg:hover {
  display: block;
  color: #ffffff;
}

.newStyle {
  padding: 0px !important;
  width: 47px !important;
}

i.fa.fa-edit.fa-lg {
  cursor: pointer;
  color: #2e78ca;
  font-size: 23px;
  background: none;
}

.tooltip-e-text {
  width: 90px;
  position: absolute;
  display: none;
  background: ghostwhite;
  padding: 10px;
  font-size: 13px;
  border-radius: 3px;
  transition: opacity 1s ease-out;
  margin-right: 40px;
}

.fa.fa-edit.fa-lg:hover + .tooltip-e-text {
  display: block;
  color: black;
}

i.fa.fa-eye.fa-lg {
  cursor: pointer;
  color: #2e78ca;
  font-size: 21px;
  background: none;
}

.tooltip-v-text {
  width: 65px;
  position: absolute;
  display: none;
  background: ghostwhite;
  padding: 10px;
  font-size: 13px;
  border-radius: 3px;
  transition: opacity 1s ease-out;
  margin-right: 40px;
}

.fa.fa-eye.fa-lg:hover + .tooltip-v-text {
  display: block;
  color: black;
}

.card .table td {
  padding: 5px 5px !important;
  text-align: center;
  vertical-align: middle !important;
  border-top: none !important;
  width: 30px;
}

.remarkStyle {
  text-align: left !important;
}

.table .thead-light th {
  border-color: #e9ecef;
  z-index: 9;
  top: -1px;
}

.css-yk16xz-control {
  align-items: center;
  border: 0 !important;
  transition: box-shadow 0.15s ease !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da !important;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 39px !important;
  outline: 0 !important;
  position: relative;
  box-sizing: border-box;
  color: #000000;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: #2e78ca !important ;
  border-color: #2e78ca !important ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #47ccde ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #2e78ca !important ;
  border-color: #47ccde;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}