.myNav{
    background-color:#072c5e !important;
}
.navItems{
    text-align: center;
    margin-left: 100px;
}
.navItem{
    color: #FFF !important;
    text-decoration: none;
    font-weight: 600;
    margin-right: 2rem;
}
.navItem:hover{
    color: #dc3545 !important;
    text-decoration: none;

}
.logBtn{
    background-color: #072c5e;
    border:1px solid #0d6efd !important;
}
.logBtn a,.signBtn a{
    text-decoration: none;
    color: #fff;    
}
.logBtn:hover{
    background-color:#0d6efd;
    font-weight: 600;
    
}

.signBtn{
   background-color:#dc3545 !important;
   border-color: #dc3545 !important;
}
 
.signBtn:hover{
    background-color:#b22836;
}
.navbar-light .navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 30 30%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath stroke=%27rgba%28240, 240, 240, 1%29%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

@media screen and (max-width: 990px){

    .logBtn{
        border: #072c5e !important;
        background-color: #072c5e !important;
    }
    .logBtn:hover{
        background-color: #072c5e;
        color: #dc3545 !important;
        text-decoration: none;
    }
    .signBtn{
        border-color: #072c5e !important;
        background-color: #072c5e !important;
    }
    .signBtn:hover{
        border: none;
        background-color: #072c5e;
        color: #dc3545 !important;
        text-decoration: none;
    }

}
@media screen and (max-width: 768px){
    .navItems{
        margin-left: 0px;
    }
    .logBtn{
        border-color:#072c5e !important;
        background-color: #072c5e !important;
    }
    .logBtn:hover{
        background-color: #072c5e;
        color: #dc3545 !important;
        text-decoration: none;
    }
    .signBtn{
        border-color: #072c5e !important;
        background-color: #072c5e !important;
    }
    .signBtn:hover{
        border: none;
        background-color: #072c5e;
        color: #dc3545 !important;
        text-decoration: none;
    }

}