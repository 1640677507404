
.white-link {
    color: rgb(227, 221, 221);
    text-decoration: none;
  }
.white-link1 {
    color:white;
    
  }
  .font-weight-bold {
    font-weight: bolder;
  }
  
  .in-principle{
    color:#111e6c;
  }

  /* SectionFifth */
  .section-fifth{
background-color: #072d5e;
  }
  .hr5{
    color: #ba1f00;
    border: 1px solid #ba1f00;
    opacity: 1;
  }
/* SectionEight */
.material-icons {
    font-size: 20px;
    vertical-align: middle;
    
    float: right;
}

