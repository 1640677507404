.error__msg,
.mustRequired {
  color: red;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 520px) {
  .app-header {
    height: 100px;
    display: -webkit-inline-box;
    padding: 5px 0;
  }

  .app-header h3 {
    font-size: 1.3rem;
  }

  .app-body {
    margin-top: 100px;
  }

  .fa {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
  }
}

i.fa.fa-eye-slash {
  background: none !important;
  border-radius: 100%;
  color: #5c6873 !important;
  width: 9px !important;
  height: 0 !important;
  line-height: 0 !important;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
}

.card-body h4 {
  color: #122a6c;
  text-transform: uppercase;
}

.form-control-label {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.form-control-alternative {
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;
}

.form-control {
  font-size: 0.875rem;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.375rem;
  border: 1px solid #cfcccc;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.css-yk16xz-control {
  align-items: center;
  border: 0 !important;
  transition: box-shadow 0.15s ease !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da !important;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 39px !important;
  outline: 0 !important;
  position: relative;
  box-sizing: border-box;
}

.css-1uccc91-singleValue {
  color: #8898aa !important;
}

.btn-new {
  color: #fff;
  background-color: #2093e4;
  border-color: #2093e4;
  font-weight: 600;
  font-size: 20px;
}
