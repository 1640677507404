@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,300&display=swap');
.br-p-50{
    padding: 50px;
}
.sm-card-mt-15{

}

.by-title{
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.underLine{
    text-decoration: none;
    border-bottom: 3px solid #e45656;
  }

  .bring-icon{
    display: flex;
    justify-content: center;
    padding: 35px;
  }

.by-pt-90{
    padding-top: 90px;
}
.by-pb-30{
    padding-bottom: 30px;
}
.box-container{
    display: flex;
    justify-content: space-between;
}
.box{
    width: 23%;
    padding: 0px 5px;
}



@media screen and (max-width: 768px) {
.by-title:before {
    top: 96px;
}

.by-pt-90{
    padding-top: 0px;
}
.by-pb-30{
    padding-bottom: 0px;
}
.br-p-50{
    padding-left: 16px;
    padding-right: 0px;
}
.sm-card-mt-15{
    margin-top: 15px !important;   
}
.box-container{
    display: inline;
}
.box{
    width: 100%;
    margin-left: -10px;
    padding-bottom: 2px;
}

  }