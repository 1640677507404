@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,300&display=swap');
.gip-pb-30{
    padding-bottom: 30px;
}
.p-50{
    padding: 50px;
}
.heading{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.underLine{
    text-decoration: none;
    border-bottom: 3px solid #e45656;
  }
  .getIn-heading{
    font-size: 45px;
  }


  .gip-sub-hd{
    margin-bottom: 5px;
  }


  @media screen and (max-width: 768px) {
    .p-50{
        padding: 50px 0px;
        
    }
    .gip-sub-hd{
        margin-bottom: 1px;
      }
      .getIn-heading{
        font-size: 28px;
        text-align: center;
      }

  }