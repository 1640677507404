.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(0 0 0 / 56%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.inner-popup{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    border: 2px solid rgb(35, 31, 39);
    border-radius: 0px;
}
.close-btn{ 
    float: right;
}
a{
    text-decoration: none;
}
