@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,300&display=swap');
.faq-card-title{
    font-family: 'Poppins';
    font-size: 21px;
    font-weight: 600;
}
.faq-pt-50{
    padding-top: 50px;
}
.faq-pl-50{
    padding-left: 50px;
}
.faq-pr-50{
    padding-right: 50px;
}
.faq-pb-30{
    padding-bottom: 30px;
}
.help-link{
    margin: 30px 50px;
}
.color-teal{
    color: #008080;
}

@media (max-width: 600px) {

.faq-pl-50{
    padding-left: 0px;
}
.faq-pr-50{
    padding-right: 0px;
}
.help-link{
    margin: 20px 0px;
}

}