@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,300&display=swap');

.headline h2{

}
.underLine{
  text-decoration: none;
  border-bottom: 3px solid #e45656;
}

.number{
padding: 1px 12px;
padding-bottom: 4px;
font-size: 21px;
font-weight: 700;
color: #fff;
background-color: rgb(7 44 94);
border: 1px solid rgb(7 44 94);
border-radius: 10px 0px 0px 10px;
margin-left: 32px;

}
.leftSec{
padding-right: 0px !important;
padding-top: 10px !important;
margin-right: -10px;
}
.rightSec{
background-color: #F4F4F9;
z-index: 1;
border: 1px solid rgb(7 44 94);
border-radius: 7px;

}
.rightSec h5{
font-family: 'Poppins', sans-serif;
color: rgb(17, 30, 108);
font-weight: 600;
font-size: 18px;
margin-bottom: 20px;
text-align: left;
}
.EstMin{
transform: rotate(-90deg);
padding: 10px 6px;
margin: 100px 0px 0px -40px;
width: 215px;
z-index: 0;
color: #fff;
background-color: #BA1F00;
border-radius: 10px 10px 0px 0px;
font-size: 20px;
font-weight: 600;
box-shadow: 0px -2px 4px #a69595;

}
.cardInfo{
margin-top: 28px;
padding-bottom: 52px;
font-size: 15px;
text-align: left;
font-weight: 400;
}
.dblArrow{
margin-top: 50px;
margin-left: -15px;
}
.dblArrow svg{
  position: relative;
  animation-name: arrow;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes arrow {
  0%   { left:0px; top:0px;}
  50%  {left:15px; top:0px;}
  100%   { left:0px; top:0px;}
}
.pcDisplay{
display: block;
}
.mDisplay{
display: none;
}


@media only screen and (max-width: 480px){
.mDisplay{
  display: block;
}
.pcDisplay{
  display: none;
}
.cardInfo{
  margin-top: 45px;
  padding-bottom: 80px;
}
.EstMin{
  margin: 110px 0px 0px -40px;
}
.headline h2{
    text-align: center;
}
}

