.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
}

.card-stats .card-body {
  padding: 1rem 1rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
}

.card-body-dtt {
  padding: 0.5rem !important;
}
svg.react_icons {
  font-size: 30px;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .card-h {
    height: 115px !important;
  }
  .card-title-mb {
    margin-bottom: 25px !important;
  }
}

.card-body h5 {
  font-size: 16.4px;
  font-weight: 700;
}

.icon-shape {
  padding: 8px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
}

.icon-shape i {
  font-size: 1.25rem;
}

.icon-shape .fa {
  background: none;
  color: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  margin-right: 0px;
  border-radius: none;
}

.card-body p .mr-2 {
  font-size: 1.5rem;
}

.card-body p {
  font-weight: 600;
  font-size: 1.05rem;
}

.calculation__card1 {
  border-radius: 5px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 1rem;
  column-gap: 10px;
  row-gap: 5px;
  background: white;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;
}

.calculation__card2 {
  border-radius: 5px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 1rem;
  column-gap: 10px;
  row-gap: 5px;
  background: white;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;
}

@media screen and (max-width: 520px) {
  .app-header {
    height: 100px;
    display: -webkit-inline-box;
    padding: 5px 0;
  }

  .app-header h3 {
    font-size: 1.3rem;
  }

  .app-body {
    margin-top: 100px;
  }

  .fa {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
  }

  .calculation__card1 h2 {
    font-size: 20px;
  }

  .calculation__card1 b {
    font-size: 16px;
    font-weight: 600;
  }
}

.form-control-label {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.form-control-alternative {
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;
}

.form-control {
  font-size: 0.875rem;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.375rem;
  border: 1px solid #cfcccc;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.css-yk16xz-control {
  align-items: center;
  border: 0 !important;
  transition: box-shadow 0.15s ease !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da !important;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 39px !important;
  outline: 0 !important;
  position: relative;
  box-sizing: border-box;
}

small {
  font-size: 68%;
  font-weight: 700;
}

.modal1 {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 3;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content1 {
  background-color: #fefefe;
  margin: 0 269px;
  padding: 34px;
  border: 1px solid #888;
}

/* The Close Button */
.close1 {
  color: #000;
  /* float: right; */
  font-size: 33px;
  font-weight: bold;
  position: absolute;
  top: 16%;
  right: 22%;
}

.close1:hover,
.close1:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

text.highcharts-credits {
  display: none !important;
}

