.weoffer{
    background-color: #d9d9d94d;
}
.subtitle{
    text-align: center;
    font-size: 20px;
    color: rgb(7, 44, 94);
}

.weoffer h2{
   color:#072c5e;
   font-size: 45px;
}

.underLine{
    text-decoration: none;
    border-bottom: 3px solid #e45656;
  }

@media screen and (max-width: 768px){
    .weOfferHeadline{
        font-size:"28px" !important;
        margin-left:"0px";
        text-align: center;
    }   
    .weoffer h2{
        font-size: 28px;
        text-align: center;
     }
     .mbox{
        display: flex;
        justify-content: center;
     }
}