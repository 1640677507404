@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,300&display=swap');

 /*Sectionfirst*/
 .mycard{
    border: none !important;
    background-color: white !important;
    font-weight: 600;
    font-size: 18px;
  }
 .mycard a{
    text-decoration: none;
    color: #000000;
  }
  .accordionTitle{
    padding: 5px; 
  }
  .accordionTitle:hover{
    color: #000000;
  }
  .accordion-arrow{
    position: absolute;
    right: 15px;
  }

   /* Home Banner */
   .title{
    font-size: 48px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: white;
    
  }
  .sub-header{
    color: white;
    font-size: 18px;
    border-left: 4px solid #00C4B2;
    padding: 20px 0px;
    width: 620px;
    font-family: 'Poppins', sans-serif;
  }
  .nav-button{
    border-radius: 1px;
    padding: 8px 0px;
    font-family: 'Poppins';
  }

  .banner-percentage{
      font-size: 24px;
      font-weight: 700;
      color:#07c6a7;
  }

  .chevrons-top{
    position: absolute;
    right: 44px;
    width: 53px;
    top: 20px;
    height: 263px;
    background: url(../../../../public/assets/img/homepage/chevrons-top.png) no-repeat center center;
    background-size: contain;
  }
  .menu-item a{
    text-decoration: none;
    color: #fff;
  }
  .hamBurger{
    display: none;
  }
  .hamBurgerItem{
    list-style: none;
    font-size: 18px;
    padding: 4px 0px;
    font-weight: 600;
    cursor: pointer;
    color: black;
   }
  .hamBurgerItem:hover{
    color:rgb(217, 12, 12);
   }
   .hamBurgerItem a{
    text-decoration: none;
    color: #000;
   }
   .hamBurgerItem a:hover{
    color: rgb(217, 12, 12);
   }

  @media (max-width: 600px) {
    .title{
      font-size: 2rem !important ;
    }
    .sub-header{
      width: 80%;
    }
    .disNon{
      display: none;
    }
    .hamBurger{
      display: block  ;
    }
    .banner-img{
      display: none;
    }
  }